<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Free Parts</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <p>
          You can select <strong>{{ partsDiff.diff }}</strong> additional
          part(s) from the <strong>{{ partsDiff.weakSide }}</strong> at no
          additional cost.
        </p>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Close" @click="$emit('close')" />
        <b-button
          :label="
            cartIndex === null ? 'Add to Cart Anyway' : 'Update Cart Anyway'
          "
          type="is-primary"
          @click="addToCartAnyway"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import $eventHub from '@/components/EventHub';

export default {
  name: 'PartsDiffModal',
  props: {
    partsDiff: Object,
    piece: Object,
    cartIndex: Number || null,
  },
  methods: {
    addToCartAnyway() {
      if (this.cartIndex === null) {
        this.$store.commit('addToCart', this.piece);
      } else {
        this.$store.commit('updateCart', {
          piece: this.piece,
          cartIndex: this.cartIndex,
        });
      }
      this.$emit('close');

      this.$buefy.snackbar.open({
        message:
          this.cartIndex === null
            ? 'Piece added to your cart!'
            : 'Cart updated!',
        type: 'is-success',
        position: 'is-top',
        actionText: 'Go to Cart',
        duration: 5000,
        onAction: () => {
          this.$router.push('/build/finalize');
        },
      });

      $eventHub.$emit('cartChange', true);
      return;
    },
  },
};
</script>
