<template>
  <div>
    <section class="section parts">
      <template v-if="isDisparate() === true">
        <div class="detail-item main-item">
          <h2 class="title is-4">{{ `Prelude:\xa0` }}</h2>
          <h3 class="subtitle is-4">
            {{ piece.prelude.type | numberToEnsembleSize | titleize }}
          </h3>
          <PartsList
            pf="prelude"
            :list="getPartsList('prelude')"
            :checkedList="checkedRows.prelude"
            :additionalPartsAvailable="additionalPreludeParts"
            @updateChecked="getChecked($event, 'prelude')"
          ></PartsList>
        </div>
        <div class="detail-item main-item">
          <h2 class="title is-4">{{ `Fugue:\xa0` }}</h2>
          <h3 class="subtitle is-4">
            {{ piece.fugue.type | numberToEnsembleSize | titleize }}
          </h3>
          <PartsList
            pf="fugue"
            :list="getPartsList('fugue')"
            :checkedList="checkedRows.fugue"
            :additionalPartsAvailable="additionalFugueParts"
            @updateChecked="getChecked($event, 'fugue')"
          ></PartsList>
        </div>
      </template>
      <template v-else>
        <!-- Make this work slightly better, like in columns -->
        <div class="detail-item">
          <h2 class="title is-5">Prelude & Fugue</h2>
          <h3 class="subtitle is-5">
            {{ piece.prelude.type | numberToEnsembleSize | titleize }}
          </h3>
          <PartsList
            :list="getPartsList()"
            :checkedList="checkedRows.prelude"
            @updateChecked="getChecked($event, 'both')"
          ></PartsList>
        </div>
      </template>
    </section>
    <section class="section options align-base">
      <div class="detail-item">
        <b-checkbox v-model="includeScore">Include Score</b-checkbox>
      </div>
      <div class="priceBlock detail-item">
        <div class="priceDescription">
          <span class="price-component basePrice">
            <b-tooltip
              position="is-right"
              label="This price includes the score and a selection of one of each Part indicated by Roman numerals for both the Prelude and Fugue."
              dashed
              multilined
            >
              Base Price:
            </b-tooltip>
            &nbsp;
            <strong>{{ price.basePrice | intToCurrency }}</strong>
            <template v-if="originalPrice.basePrice != 0">
              <strong class="original-price">{{
                originalPrice.basePrice | intToCurrency
              }}</strong>
            </template>
          </span>
          <template v-if="price.basePrice === 0 && includeScore === true">
            &nbsp;
            <span class="price-component scorePrice">
              <b-tooltip
                position="is-right"
                label="This is the price of the score if not covered by the Base Price."
                dashed
                multilined
              >
                Score:
              </b-tooltip>
              &nbsp;
              <strong>{{ price.scorePrice | intToCurrency }}</strong>
            </span>
          </template>
          <template v-if="price.additionalParts > 0">
            &nbsp;
            <span class="price-component additionalParts">
              <b-tooltip
                position="is-right"
                :label="
                  'This price includes any selected parts not covered by the Base Price. ' +
                  (this.isDisparate()
                    ? 'Each Additional Part is for a single selection from the Prelude and Fugue respectively.'
                    : 'Each Additional Part is for a single selection containing both the Prelude and Fugue for that instrument.')
                "
                dashed
                multilined
              >
                Additional Parts:
              </b-tooltip>
              &nbsp;
              <strong>
                <b-tooltip
                  position="is-bottom"
                  :label="`${price.additionalPartsCount} part(s) at $6.00 a part`"
                  dashed
                >
                  {{ price.additionalParts | intToCurrency }}
                </b-tooltip>
              </strong>
            </span>
          </template>
        </div>
        <div class="price">
          Total:
          {{ price.total | intToCurrency }}
          <template v-if="originalPrice.basePrice != 0">
            <span class="was">was</span>
            <span class="original-price">
              {{ originalPrice.total | intToCurrency }}
            </span>
          </template>
        </div>
        <!-- {{ calculateCustomPrice(piece, checkedRows) }} -->
      </div>
      <b-button
        class="detail-item"
        v-if="showPreviewButton"
        type="is-primary"
        outlined
        icon-pack="fas"
        icon-left="eye"
        @click="showPreview(piece)"
        >Show Preview</b-button
      >
      <b-button
        class="detail-item"
        type="is-danger"
        @click="clearCheckedRows"
        :disabled="clearCheckedDisabled"
        icon-left="backspace"
        outlined
      >
        Reset Parts Selection
      </b-button>
      <b-tooltip
        class="detail-item"
        :label="
          addToCartDisabled
            ? 'Make sure to select one of each Part indicated by the roman numerals for both the prelude and fugue.'
            : 'Ready to go!'
        "
        :type="addToCartDisabled ? 'is-danger' : 'is-success'"
        position="is-bottom"
        multilined
      >
        <b-button
          type="is-dark"
          @click="addToCart"
          :disabled="addToCartDisabled"
          icon-left="shopping-cart"
          outlined
        >
          {{ isUpdateContext ? 'Update Cart' : 'Add to Cart' }}
        </b-button>
      </b-tooltip>
    </section>
    <section class="section" v-if="piece.description">
      <template>
        <div class="description detail-item">
          <div v-if="piece.description">
            <b-message title="Program Notes" :closable="false"
              ><div class="program-notes" v-html="piece.description"
            /></b-message>
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
// TODO: move these to common or list
import PartsList from '@/components/Piece/PartsList';
import ScorePreviewIncipit from '@/components/ScorePreviewIncipit';
import PartsDiffModal from '@/components/Piece/PartsDiffModal';
import Pricing from '@/mixins/Pricing';
import Parts from '@/mixins/Parts';
import $eventHub from '@/components/EventHub';

export default {
  name: 'Piece',
  components: { PartsList },
  props: {
    bwv: {
      type: Number,
    },
    showPreviewButton: {
      type: Boolean,
    },
  },
  mixins: [Pricing, Parts],
  computed: {
    additionalPreludeParts() {
      const { prelude: checkedPreludeParts = [] } = this.checkedRows;
      const {
        prelude: { parts: totalPreludeParts = [] },
      } = this.piece;
      const remaining = this.getRemainingFreeParts(this.checkedRows);

      if (remaining.weakSide === 'prelude') {
        const checkableParts =
          totalPreludeParts.length - checkedPreludeParts.length;
        return remaining.diff > checkableParts
          ? checkableParts
          : remaining.diff;
      }

      return 0;
    },
    additionalFugueParts() {
      const { fugue: checkedFugueParts = [] } = this.checkedRows;
      const {
        fugue: { parts: totalFugueParts = [] },
      } = this.piece;
      const remaining = this.getRemainingFreeParts(this.checkedRows);

      if (remaining.weakSide === 'fugue') {
        const checkableParts =
          totalFugueParts.length - checkedFugueParts.length;
        return remaining.diff > checkableParts
          ? checkableParts
          : remaining.diff;
      }

      return 0;
    },
    isUpdateContext() {
      return this.cartIndex !== null;
    },
    piece() {
      const {
        bwv,
        $store: {
          getters: { pieces },
        },
      } = this;
      return pieces.find((piece) => piece.bwv == bwv);
    },
    cartIndex() {
      return this.$route.params.checkedCartIndex ?? null;
    },
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
    ensembleSorted() {
      return this.$store.getters.userEnsembleSorted;
    },
    price() {
      return this.calculateCustomPrice(
        this.piece,
        this.checkedRows,
        this.includeScore
      );
    },
    originalPrice() {
      return this.calculateOriginalCustomPrice(
        this.piece,
        this.checkedRows,
        this.includeScore
      );
    },
  },
  created() {
    // this.price = this.calculatePrice(this.piece, this.checkedRows);
    //Automatically check the parts if it is passed thru.
    this.preCheckRows();
  },
  data() {
    return {
      checkedRows: {
        prelude: [],
        fugue: [],
      },
      //TODO: do we need to actually store it?
      checkedReady: {
        prelude: false,
        fugue: false,
      },
      clearCheckedDisabled: true,
      addToCartDisabled: true,
      includeScore: false,
      additionalPartsAvailable: { prelude: 0, fugue: 0 },
      prechecked: false,
    };
  },
  methods: {
    hasAnyCheckedRow() {
      return (
        this.checkedRows.prelude?.length > 0 ||
        this.checkedRows.fugue?.length > 0 ||
        this.includeScore
      );
    },
    showPreview(piece) {
      this.$buefy.modal.open({
        component: ScorePreviewIncipit,
        props: {
          piece,
        },
      });
    },
    filterPartsByEnsemble() {
      console.log(
        this.getFilteredPartsByEnsemble(this.piece, this.ensembleSorted)
      );
      const { prelude, fugue, both } = this.getFilteredPartsByEnsemble(
        this.piece,
        this.ensembleSorted
      );

      prelude && this.getChecked(prelude, 'prelude');
      fugue && this.getChecked(fugue, 'fugue');
      both && this.getChecked(both, 'both');
    },
    // TODO: this doesn't work properly for piece choosing
    preCheckRows: function () {
      // Flag for checkedRows watcher to ignore computing whether to include/exclude score
      this.prechecked = true;

      let cartIndex = this.cartIndex;
      console.log('pre check rows...user ensemble', this.userEnsemble);
      if (!this.isUpdateContext) {
        // Still pre check rows if an ensemble is given
        this.filterPartsByEnsemble();
        return;
      }
      let cart = this.$store.getters.userCart;
      let pieceInCart = cart[cartIndex];
      console.log('pieceInCart', pieceInCart);
      this.getChecked(pieceInCart.selectedParts.prelude, 'prelude');
      this.getChecked(pieceInCart.selectedParts.fugue, 'fugue');
      this.includeScore = pieceInCart.score;
    },
    clearCheckedRows: function () {
      this.checkedRows = {
        prelude: [],
        fugue: [],
      };
      this.includeScore = false;
      // this.price = this.calculatePrice(this.piece, this.checkedRows);
    },
    isDisparate: function () {
      let piece = this.piece;
      return piece.prelude.type !== piece.fugue.type;
    },
    getPartsList: function (which) {
      let parts;
      if (which === 'prelude') {
        parts = this.piece.prelude.parts;
      } else if (which === 'fugue') {
        parts = this.piece.fugue.parts;
      }
      if (typeof parts === 'undefined') {
        parts = this.piece.parts;
      }
      return parts;
    },

    addToCart: function () {
      const cartIndex = this.cartIndex;
      let piece = {
        piece: this.piece,
        selectedParts: this.checkedRows,
        price: this.price,
        originalPrice: this.originalPrice,
        score: this.includeScore,
      };
      const partsDiff = this.getRemainingFreeParts(this.checkedRows);

      if (Object.entries(partsDiff).length > 0) {
        this.$buefy.modal.open({
          parent: this,
          component: PartsDiffModal,
          props: {
            partsDiff,
            piece,
            cartIndex,
          },
          hasModalCard: true,
          trapFocus: true,
        });

        return;
      }

      if (this.isUpdateContext) {
        this.$store.commit('updateCart', { piece, cartIndex });
      } else {
        this.$store.commit('addToCart', piece);
      }

      //If we're good... add a notification for user feedback.
      this.$buefy.snackbar.open({
        message: this.isUpdateContext
          ? 'Cart updated!'
          : 'Piece added to your cart!',
        type: 'is-success',
        position: 'is-top',
        actionText: 'Go to Cart',
        duration: 4000,
        onAction: () => {
          this.$router.push('/build/finalize');
        },
      });

      $eventHub.$emit('cartChange', true);
    },
    getChecked: function (checkedRows, piece) {
      //Sort them by idea to remove any user sorting.
      checkedRows.sort((a, b) => (a.id > b.id ? 1 : -1));
      switch (piece) {
        case 'prelude':
          this.checkedRows.prelude = checkedRows;
          break;
        case 'fugue':
          this.checkedRows.fugue = checkedRows;
          break;
        case 'both':
          this.checkedRows = {
            prelude: checkedRows,
            fugue: checkedRows,
          };
          break;
      }
      // this.price = this.calculatePrice(this.piece, this.checkedRows);
    },
    //Check if each part has a minimum of one instrument per Part. If so, we're ready to go. Anything on top is gravy.
    isPieceCheckedReady: function (preFug) {
      //TODO: error condition if preFug is not exactly "prelude" or "fugue"
      let piece = this['piece'][preFug];
      let checked = this['checkedRows'][preFug];
      let partCheck = {};
      for (let i = 1; i <= piece['type']; i++) {
        let found = checked.filter(function (inst) {
          return inst.part == i;
        });
        partCheck[i] = found.length > 0;
      }
      let finalCheck = true;
      Object.keys(partCheck).forEach((key) => {
        if (partCheck[key] === false) {
          finalCheck = false;
        }
      });
      return finalCheck;
    },
  },
  watch: {
    // Add to cart and reset cart actions are dependent on checked rows state
    checkedRows: {
      handler(newValue) {
        this.addToCartDisabled = !this.hasAnyCheckedRow();
        this.clearCheckedDisabled = !this.hasAnyCheckedRow();

        if (this.prechecked && this.isUpdateContext) {
          this.prechecked = false;
        } else {
          this.includeScore = this.isPieceFullyChecked(newValue, this.piece);
        }
      },
      deep: true,
    },
    // Actions are also dependent on the score option
    includeScore(value) {
      if (value) {
        this.addToCartDisabled = false;
        this.clearCheckedDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
.section {
  display: flex;
  justify-content: center;
}
@include touch {
  .section {
    flex-wrap: wrap;
  }
}
.detail-item.main-item h2,
.detail-item.main-item h3 {
  display: inline-block;
}
.section.parts,
.section.options {
  max-width: $fullhd;
  margin: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.section.options {
  justify-content: flex-start;
  // min-height: 244px;
}
.section.align-base {
  align-items: flex-end;
}
.description p {
  margin-bottom: 1em;
}

.detail-item {
  margin: 20px;
}
@include touch {
  .detail-item {
    margin: 5px;
    width: 100%;
  }

  .b-tooltip.detail-item button {
    width: 100%;
  }
}
.main-item,
.description {
  flex: auto;
  width: min-content;
}
.description {
  max-width: $fullhd;
  margin: auto;

  @include touch {
    max-width: 100%;
    margin-bottom: $gap;
  }
}
.price-component {
  display: block;
}
.priceBlock {
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 188px;
}
.priceBlock .price {
  font-size: 1.7em;
  font-weight: bold;
  color: $blue;
}
.priceBlock .priceDescription {
  font-size: 1em;
}

.was {
  font-size: 1rem;
  font-weight: normal;
  color: $gray;
}
</style>

<style lang="scss">
.program-notes p {
  margin: revert;
}
</style>
