<template>
  <section class="section">
    <h1 class="title">
      Prelude and Fugue No. {{ piece.no }} in
      <span v-html="$options.filters.keySymbols(piece.key)"></span>
    </h1>
    <h2 class="subtitle">BWV {{ piece.bwv }}</h2>
    <Piece :bwv="piece.bwv" showPreviewButton />
  </section>
</template>

<script>
import Piece from '@/components/Piece/Piece';
export default {
  name: 'PiecePage',
  components: { Piece },
  computed: {
    piece() {
      const {
        $route: {
          params: { bwv },
        },
        $store: {
          getters: { pieces },
        },
      } = this;
      const piece = pieces.filter((piece) => piece.bwv == bwv);
      return piece[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
.title,
.subtitle {
  max-width: $fullhd;
  margin: auto;
}
.priceBlock {
  margin-top: 15px;
  margin-bottom: 15px;
}
.priceBlock .price {
  font-size: 1.7em;
  font-weight: bold;
  color: darkgreen;
}
.priceBlock .priceDescription {
  font-size: 1em;
}
</style>
