<template>
  <div id="part_list" class="content">
    <b-table
      class="parts-table"
      :data="partListWithSeparators"
      :checked-rows.sync="checkedRows"
      :mobile-cards="false"
      :narrowed="true"
      :hoverable="true"
      checkable
      checkbox-position="left"
      :is-row-checkable="(row) => row.part"
      v-on:check="userChecked"
      v-on:mouseenter="isRowValid"
      :row-class="(row, index) => getRowClass(row, index)"
    >
      <b-table-column field="part" class="part" label="Part" v-slot="part">
        <span class="roman-numeral" v-if="part.row.part">{{
          parseInt(part.row.part) | numberToRoman
        }}</span>
      </b-table-column>
      <b-table-column class="instruments" label="Instruments" v-slot="part">
        <span v-html="part.row.stringifiedInstruments"></span>
      </b-table-column>
      <b-table-column v-slot="part">
        <span v-if="!isRowValid(part.row)" class="tag is-warning hover-warning">
          <b-icon icon="exclamation" size="is-small" />
        </span>
      </b-table-column>
      <template #footer v-if="hasUnavailablePart()">
        <div class="has-text-right">
          <span class="tag is-warning">Your ensemble is incompatible</span>
        </div>
      </template>
      <template slot="bottom-left">
        <div class="footer-wrapper">
          <p class="footer-item">
            <strong>Total Checked:</strong> {{ checkedRows.length }}
          </p>
          <template v-if="additionalPartsAvailable > 0">
            <b-tooltip
              position="is-bottom"
              dashed
              multilined
              :label="
                'You can select this many additional parts from the ' +
                this.$options.filters.titleize(pf) +
                ' at no additional cost.'
              "
            >
              <p>
                <strong>Additional Parts Available:</strong>
                {{ additionalPartsAvailable }}
              </p></b-tooltip
            >
          </template>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'PartsList',
  props: {
    list: Array,
    pf: String,
    checkedList: Array,
    additionalPartsAvailable: Number,
  },
  data() {
    return {
      checkedRows: [],
    };
  },
  mounted() {
    console.log('parts list list', this.list);
    this.checkedRows = this.initCheckedRows();
  },
  computed: {
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
    // Same parts list with dummy rows between each differing part number for display only
    partListWithSeparators() {
      let partList = this.partList;
      let partsListWithSeparators = [];

      // Copy part list and add dummy parts between changing parts
      partList.forEach((p, i) => {
        partsListWithSeparators.push(p);
        if (partList[i + 1] && partList[i + 1]?.part > p.part) {
          const dp = { ...p };
          delete dp.part;
          delete dp.stringifiedInstruments;
          partsListWithSeparators.push(dp);
        }
      });

      return partsListWithSeparators;
    },
    //Make the instruments have their full names.
    partList: function () {
      const partList = this.list;
      const formalInstruments = this.$store.state.instruments;

      return partList.map((row) => {
        const { instruments } = row;
        const labels = instruments.map((inst) => {
          const instrumentMatch = formalInstruments.find(
            (formalInstrument) => formalInstrument.id === inst
          );
          return this.$options.filters.displayInstrumentName(instrumentMatch);
        });
        return { stringifiedInstruments: labels.join(', '), ...row };
      });
    },
  },
  methods: {
    // Method to check whether checked parts are available to current ensemble
    hasUnavailablePart() {
      const vue = this;
      return this.checkedRows.some((row) => !vue.isRowValid(row));
    },
    // Check whether current row is valid against current ensemble
    isRowValid(row) {
      if (this.userEnsemble.length === 0) {
        return true;
      }
      const currEnsembleInstruments = this.userEnsemble.map((t) => t.id);
      return currEnsembleInstruments.some((ins) =>
        row.instruments.includes(ins)
      );
    },
    userChecked: function (list) {
      this.$emit('updateChecked', list);
    },
    initCheckedRows: function () {
      //Convert the checked list into the checked rows.
      const partList = this.partList;
      const checkedList = this.checkedList;
      const finalChecked = checkedList.map(function (checked) {
        for (let i = 0; i < partList.length; i++) {
          if (partList[i].id == checked.id) {
            return partList[i];
          }
        }
      });
      return finalChecked;
    },
    // Method to return part-1 if the row's part object is part 1 and also a class for whether it's the last one of its part.
    //TODO: sometimes the separators get gorked.
    getRowClass(row, index) {
      let classes = [];
      const prevPartValid =
        this.partListWithSeparators[index - 1] &&
        this.partListWithSeparators[index - 1].part;
      const nextPartValid =
        this.partListWithSeparators[index + 1] &&
        this.partListWithSeparators[index + 1].part;

      if (!row.part) {
        // If the row doesn't have a part property it's a separator row
        return `part-separator`;
      }

      // Return "part-n" by default
      classes.push(`part-${row.part}`);

      if (!this.isRowValid(row)) {
        classes.push('unavailable');
      }

      if (!nextPartValid) {
        // Consider part a "last" part if its next part is a separator row or it's the last part
        classes.push('is-last-part');
        // return `part-${row.part} is-last-part`;
      } else if (!prevPartValid) {
        // Consider part a first part if its previous part is a separator row or if it's the first part
        classes.push('is-first-part');
        // return `part-${row.part} is-first-part`;
      }

      return classes.map((c) => c.toLowerCase()).join(' ');
    },
  },
  watch: {
    checkedList() {
      this.checkedRows = this.initCheckedRows();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

.roman-numeral {
  font-family: $family-serif;
  font-weight: bold;
}

tr.is-first-part td {
  border-top: transparent;
}

tr.is-last-part td {
  border-bottom: transparent;
}

.hover-warning {
  visibility: hidden;
  float: right;

  @include touch {
    display: none;
  }
}

.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.footer-item {
  flex-basis: 100%;
}
</style>

<style lang="scss">
@import '@/sass/styles';

.parts-table table {
  @include touch {
    overflow: hidden;
  }
}

tr.part-separator td {
  height: 32px;
  border-top: transparent;
  border-bottom: transparent;
}

.part-separator .checkbox.is-disabled {
  display: none !important;
}

.is-checked {
  background-color: $selected;
}

.is-checked.unavailable {
  .hover-warning {
    visibility: visible;
  }

  @include touch {
    .hover-warning {
      display: inline-flex;
    }
  }
}

td[data-label='Part'] {
  width: 55px;
}

td.checkbox-cell {
  border-color: transparent !important;
}

tr.unavailable:hover {
  .hover-warning {
    visibility: visible;
  }
}
</style>
